<template>
	<el-dialog title="服务记录" :visible.sync="dialogVisible" :close-on-click-modal="false" width="80%" :before-close="handleClose">
		<div class="top">
			<el-date-picker
				v-if="showType === 'week'"
				v-model="selectWeekValue"
				style="width: 250px"
				type="week"
				:format="startDate + '至 ' + endDate"
				placeholder="请选择日期"
				@change="changeWeek"
				:picker-options="{ firstDayOfWeek: 1 }"
			/>
			<el-date-picker
				v-else
				v-model="timeRange"
				type="daterange"
				range-separator="至"
				start-placeholder="开始日期"
				end-placeholder="结束日期"
				@change="dateChange"
				value-format="yyyy-MM-dd"
				format="yyyy 年 MM 月 dd 日"
			/>
		</div>
		<el-table style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle" v-loading="loading">
			<template slot="empty">
				<IsEmpty />
			</template>
			<el-table-column prop="serviceTime" label="服务时间" align="center" />
			<el-table-column prop="serviceProject" label="服务内容" align="center" />
			<el-table-column prop="servicePrice" label="服务金额" align="center" />
			<el-table-column prop="serviceDuration" label="服务时长" align="center" />
			<el-table-column prop="serviceName" label="服务机构" align="center" />
			<el-table-column prop="staffName" label="服务人员" align="center" />
			<el-table-column prop="date6" label="服务图片" align="center">
				<template slot-scope="scope">
					<el-button type="text" style="margin-left: 0" @click="seeImage(scope.row)">查看图片</el-button>
				</template>
			</el-table-column>
			<el-table-column prop="address6" label="操作" align="center">
				<template slot-scope="scope">
					<el-button type="text" @click="seeOrderDetail(scope.row.workOrderId)">查看详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			background
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
			:current-page="tableParams.pageNum"
			:page-sizes="[10, 20, 30, 40]"
			:page-size="tableParams.pageSize"
			layout="total, sizes, prev, pager, next"
			:total="total"
		>
		</el-pagination>
		<div slot="footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="handleClose">确 定</el-button>
		</div>
		<!-- 查看图片弹窗 -->
		<ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
		<!-- 工单详情 -->
		<OrderDetail :drawerShow.sync="drawerShow" :orderId="orderId" />
	</el-dialog>
</template>

<script>
import { getCurrentMonth } from '@/utils/date.js'
import { getBedWorkOrderList } from '@/api/workManage'
import OrderDetail from './orderDetail.vue'
export default {
	// 床位监管--服务订单
	name: 'orderList',
	components: {
		OrderDetail,
	},
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		bedId: {
			type: Number,
			default: null,
		},
		showType: {
			type: String,
			default: 'week',
		},
	},
	data() {
		return {
			originMonth: '',
			startDate: '',
			endDate: '',
			timeRange: '',
			selectWeekValue: '',
			tableData: [{ name: 'name' }],
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 0,
			loading: false,
			imageVisible: false,
			imagesList: [],
			drawerShow: false,
			orderId: '',
		}
	},
	created() {
		this.originMonth = this.getCurrentMonth()
		this.selectWeekValue = Date()
		this.changeWeek(Date())
	},
	mounted() {},
	methods: {
		getCurrentMonth,
		timestampToTime(timestamp) {
			var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
			let Y = date.getFullYear() + '-'
			let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
			let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
			return Y + M + D
		},
		dateChange() {
			this.startDate = this.timeRange ? this.timeRange[0] : ''
			this.endDate = this.timeRange ? this.timeRange[1] : ''
			this.updateInfo()
		},
		changeWeek(val) {
			// val===> Mon Nov 09 2020 00:00:00 GMT+0800 (中国标准时间)
			let todayDay = val ? new Date(val) : new Date()
			let todayOfWeek = todayDay.getDay()
			let spendDay = 1
			if (todayOfWeek !== 0) {
				spendDay = 7 - todayOfWeek // 开始时间到结束时间还有几天
			}
			//  86400000为一天的毫秒数
			this.startTimeNum = todayDay.valueOf() - (6 - spendDay) * 86400000 // 开始时间时间戳
			this.endTimeNum = todayDay.valueOf() + spendDay * 86400000 // 结束时间时间戳
			this.startDate = this.timestampToTime(this.startTimeNum) // 时间戳转字符串
			this.endDate = this.timestampToTime(this.endTimeNum)
			this.updateInfo()
		},
		handleClose() {
			this.$emit('update:dialogVisible', false)
		},
		updateInfo() {
			this.tableParams.pageNum = 1
			this.getList()
		},
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.tableParams.pageNum = 1
			this.getList()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		getList() {
			this.loading = true
			getBedWorkOrderList({ ...this.tableParams, startDate: this.startDate, endDate: this.endDate, bedId: this.bedId })
				.then((res) => {
					this.tableData = res.data.records
					this.total = res.data.total
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
		// 查看图片
		seeImage(row) {
			const imgs = [...row.serviceFrontImgs, ...row.serviceBackImgs]
			if (imgs && imgs.length > 0) {
				const imgsList = imgs.map((item) => {
					return { url: item }
				})
				this.imagesList = imgsList
				this.imageVisible = true
			} else {
				this.$message.info('暂无图片')
			}
		},
		// 查看订单详情
		seeOrderDetail(orderId) {
			this.drawerShow = true
			this.orderId = orderId
		},
	},
}
</script>

<style lang="scss" scoped>
.top {
	text-align: center;
	margin-bottom: 0.15625rem /* 30/192 */;
}
</style>
