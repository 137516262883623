export function getDate(num) {
	var dateNow = new Date()
	var newY = dateNow.getFullYear()
	if (newY < 10) {
		newY = '0' + newY
	}
	var newM = dateNow.getMonth() + 1
	if (newM < 10) {
		newM = '0' + newM
	}
	var newD = dateNow.getDate()
	if (newD < 10) {
		newD = '0' + newD
	}
	// 获取今日日期
	var endDate = newY + '-' + newM + '-' + newD
	var dateNum = new Date(dateNow)
	// 获取今日前num日期
	dateNum.setDate(dateNow.getDate() - num)
	var oldY = dateNum.getFullYear()
	if (oldY < 10) {
		oldY = '0' + oldY
	}
	var oldM = dateNum.getMonth() + 1
	if (oldM < 10) {
		oldM = '0' + oldM
	}
	var oldD = dateNum.getDate()
	if (oldD < 10) {
		oldD = '0' + oldD
	}
	var startDate = oldY + '-' + oldM + '-' + oldD
	return [startDate, endDate]
}

export function isPhone(phone) {
	var reg = /(1[3-9]\d{9}$)/
	return reg.test(phone)
}

export function getCurrentDateAndWeek() {
	const date = new Date()
	const year = date.getFullYear() //年
	const month = date.getMonth() + 1 //月
	const day = date.getDate() //日
	let days = date.getDay()
	switch (days) {
		case 1:
			days = '星期一'
			break
		case 2:
			days = '星期二'
			break
		case 3:
			days = '星期三'
			break
		case 4:
			days = '星期四'
			break
		case 5:
			days = '星期五'
			break
		case 6:
			days = '星期六'
			break
		case 0:
			days = '星期日'
			break
	}
	let str = year + '-' + month + '-' + day + '|' + days
	return str
}

export function getCurrentMonth() {
	var now = new Date()
	var year = now.getFullYear() //得到年份
	var month = now.getMonth() + 1 //得到月份
	month = month.toString().padStart(2, '0')
	const defaultDate = `${year}-${month}`
	return defaultDate
}
