<template>
	<div class="disableList">
		<StatisticsInfo :list="list" />
		<el-card style="margin-top: 20px">
			<SearchFrom timeType="range" timeLable="停发时间" :showOrgan="true" :showService="false" @searchSubmit="doSearch" exportType="stop" />
			<el-table style="width: 100%" :data="tableData" border :header-cell-style="headerCellStyle" v-loading="loading">
				<template slot="empty">
					<IsEmpty />
				</template>
				<el-table-column type="index" label="序号" width="50" />
				<el-table-column prop="userName" label="老人姓名" align="center" width="100" />
				<el-table-column prop="phone" label="联系方式" align="center" width="120" />
				<el-table-column prop="street" label="所属组织" align="center" width="200" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<span>{{ scope.row.tenantName ? `${scope.row.tenantName}/${scope.row.organizationName}` : '--' }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="organName" label="服务机构" align="center" width="120" />
				<!-- <el-table-column prop="detaAddress" label="详细地址" align="center" width="200" :show-overflow-tooltip="true" /> -->
				<el-table-column prop="createTime" label="申请时间" align="center" width="160" />
				<el-table-column prop="stopDate" label="停发时间" align="center" width="160" />
				<el-table-column prop="stopReasonText" label="停发原因" align="center" width="160" :show-overflow-tooltip="true" />
				<el-table-column prop="stopRemark" label="停发备注" align="center" width="160" :show-overflow-tooltip="true" />
				<!-- <el-table-column prop="cardId" label="身份证" align="center" width="180" /> -->
				<el-table-column prop="elderlyAbility" label="老人能力评估" align="center" width="120">
					<template slot-scope="scope">
						{{ getContent(ELDERLYABILITY, scope.row.elderlyAbility, 'label', 'text') }}
					</template>
				</el-table-column>
				<el-table-column prop="elderlyType" label="老人类型" align="center" width="160" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						{{ getContent(ELDERLYTYPE, scope.row.elderlyType, 'label', 'text') }}
					</template>
				</el-table-column>
				<el-table-column prop="serviceSubsidy" label="服务补贴标准(%)" align="center" width="140" />
				<el-table-column prop="serviceMaxFee" label="服务最高标准(元)" align="center" width="140" />
				<el-table-column prop="serviceAgreementImg" label="服务协议" align="center" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="downFile(scope.row.allFiles)">查看附件</el-button>
						<el-button type="text" style="margin-left: 0" @click="$zoomIn(scope.row.allFiles !== null ? scope.row.allFiles.imgAddress : [])">查看图片</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="monthSumValue" label="养老服务金额" align="center" width="120" />
				<el-table-column prop="monthCount" label="养老服务次数" align="center" width="120" />
				<el-table-column prop="stopOptName" label="停发操作人" align="center" width="120" />
				<el-table-column prop="address6" label="操作" width="150" align="center" fixed="right">
					<template slot-scope="scope">
						<el-button type="text" @click="seeOrder(scope.row.id)">查看服务订单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="tableParams.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="tableParams.pageSize"
				layout="total, sizes, prev, pager, next"
				:total="total"
			>
			</el-pagination>
		</el-card>
		<!-- 服务订单弹窗 -->
		<OrderList v-if="dialogVisible" :dialog-visible.sync="dialogVisible" :bedId="bedId" showType="range" />
		<!-- 查看图片弹窗 -->
		<ImgaeDialog v-if="imageVisible" :imageVisible.sync="imageVisible" :imagesList="imagesList" />
	</div>
</template>

<script>
import StatisticsInfo from './components/statisticsInfo.vue'
import SearchFrom from './components/searchFrom.vue'
import OrderList from './components/orderList.vue'
import { getContent, downloadFile } from '@/utils/index'
import { BUILDALLSTATE, ELDERLYABILITY, ELDERLYTYPE } from '@/data/dataDictionary'
import { bedStopPage, getStopCount } from '@/api/bedManage'
export default {
	// 停发列表
	name: 'disableList',
	components: {
		StatisticsInfo,
		SearchFrom,
		OrderList,
	},
	data() {
		return {
			list: [{ url: require('../../assets/imgs/disable.png'), num: 100, title: '目前停发人数' }],
			BUILDALLSTATE,
			ELDERLYABILITY,
			ELDERLYTYPE,
			loading: false,
			dialogVisible: false,
			imageVisible: false,
			imagesList: [],
			tableData: [],
			searchForm: {
				keyWords: '',
				elderlyAbility: null,
				elderlyType: null,
				sysId: '',
				startTime: '',
				endTime: '',
			},
			tableParams: {
				pageSize: 10,
				pageNum: 1,
			},
			total: 0,
			bedId: null, // 床位ID
		}
	},
	mounted() {
		this.getCount()
		this.getList()
	},
	methods: {
		getContent,
		// 获取统计数据
		getCount() {
			getStopCount()
				.then((res) => {
					this.list[0].num = res.data
				})
				.catch(() => {
					this.list[0].num = 0
				})
		},
		handleSizeChange(pageSize) {
			this.tableParams.pageSize = pageSize
			this.tableParams.pageNum = 1
			this.getList()
		},
		handleCurrentChange(pageNum) {
			this.tableParams.pageNum = pageNum
			this.getList()
		},
		doSearch(params) {
			this.searchForm = params
			this.tableParams.pageNum = 1
			this.getList()
		},
		getList() {
			this.loading = true
			bedStopPage({ ...this.tableParams, ...this.searchForm })
				.then((res) => {
					this.tableData = res.data.items
					this.total = res.data.counts
					this.loading = false
				})
				.catch(() => {
					this.tableData = []
					this.total = 0
					this.loading = false
				})
		},
		// 查看附件
		downFile(fileUrl) {
			if (fileUrl && fileUrl.fileAddress.value) {
				const href = fileUrl.fileAddress.value
				downloadFile(href)
			} else {
				this.$message.info('暂无附件')
			}
		},
		// 查看图片
		seeImage(imgs) {
			if (imgs && imgs.imgAddress.length > 0) {
				const imgsList = imgs.imgAddress.map((item) => {
					return { url: item.value }
				})
				this.imagesList = imgsList
				this.imageVisible = true
			} else {
				this.$message.info('暂无图片')
			}
		},
		// 查看服务订单
		seeOrder(bedId) {
			this.bedId = bedId
			this.dialogVisible = true
		},
	},
}
</script>

<style lang="scss" scoped></style>
